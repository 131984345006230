@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;


/* Hero Section */
.hero-carousel .owl-stage-outer {
    border-radius: 30px;
}

.hero-carousel .owl-dots {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.hero-carousel .owl-dots .owl-dot {
    border-width: 10px;
    border-color: yellow;
}

.owl-dots .owl-dot span {
    width: 20px;
    height: 4px;
    margin: 5px 5px;
    background: #cfd5d8;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #0D2C3B;
    width: 40px;
    height: 4px;
}

/* .owl-stage-outer {
    height: 600px;
} */

.hero-carousel .owl-nav {
    height: 0 !important;
}

@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-scroll {
    animation: scroll 20s linear infinite;
  }

  