.root {
  overflow: hidden;
};

@media only screen and (max-width: 1024px) {
  .guide_weather_widget  .widget-right{
    width: 100% !important
  }
}
@media only screen and (max-width: 768px) {
  select,   
textarea,   
input[type="text"],   
input[type="password"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="date"], 
input[type="month"], 
input[type="time"], 
input[type="week"], 
input[type="number"], 
input[type="email"], 
input[type="url"], 
input[type="search"], 
input[type="tel"], 
input[name="date"],
input[type="color"] {
   font-size: 16px; 
  }
   
}

// Styles of the Radio Buttons (start)
.custom-radio:after {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  top: -1px;
  left: 0px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #3842B2;
}

.custom-radio:checked:after {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  top: -1px;
  left: 0px;
  position: relative;
  background-color: #3842B2;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

// Styles of the Radio Buttons (end)


// showing three dots on the second line on the tripideas section (start)
.block-with-text {
  overflow: hidden;
  position: relative; 
  line-height: 1.2em;
  max-height: 3.6em; 
  text-align: justify;  
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.block-with-text:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 3px;
}
/* hide ... if we have text, which is less than or equal to max lines */
.block-with-text:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: transparent;
}
// showing three dots on the second line on the tripideas section (end)

.header-gradient {
  background: linear-gradient(235.83deg, rgba(20, 35, 200, 0) 20%, rgba(2, 13, 137, 0.69) 76.21%)
}

  .widget-right{
    box-shadow: none !important;
  }

  // sidemenu on avuxi widget going behind the category buttons 
.vx-map-with-scores-3__categories-box {
  z-index: 400 !important;
};

.hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-10;
};

.topnotreach{
  @apply bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700;
}
